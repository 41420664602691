import { Component, Vue } from 'vue-property-decorator'
import FormObservationCrm from '@/components/CrmForm/Observation/Observation.vue'
import Crm from '@/Models/Crm'
import http from '@/services/http'
import ObservationCrm from '@/Models/ObservationCrm'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import menuModule from '@/store/menu'
import router from '@/router'
import Header from '@/components/Header/Header.vue'
import Animation from '@/components/Animation/index.vue'
@Component({
  components: {
    FormObservationCrm,
    ModalDelete,
    Header,
    Animation,
  },
})
export default class ViewObservation extends Vue {
  showList = true
  data: Array<ObservationCrm> = []
  objObservationCrm: ObservationCrm = new ObservationCrm()
  action = 'add'
  member: Crm = new Crm()
  currentPage = 1
  loading = false
  delete_modal_active = false
  //th de la tabla
  header: Array<any> = [
    {
      field: 'reading_date',
      label: 'Fecha',
    },
    {
      field: 'observation',
      label: 'Observacion',
    },
  ]
  mounted() {
    this.getObservationCrm()
    ;(this.$refs.header as any).isViewPrincipal = false
  }
  async getObservationCrm() {
    this.loading = true
    try {
      const id = menuModule.id_param
      if (id == null) throw router.push({ name: 'CCSS' }).catch(console.log)
      const res = await http.get(`api/crm/crm_observation/${id}/`)
      if (!res.data) throw new Error(res.data)
      if (res.data.observations != 'Este miembro no tiene observaciones') {
        this.data = res.data.observations.map((obj: any) => ({
          ...obj,
          reading_date: new Date(obj.date_observation)
            .toISOString()
            .split('T')[0],
          date_observation: new Date(obj.date_observation),
        }))
      }
      this.member = res.data.member[0]
    } catch (error) {
      //console.error(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
    this.loading = false
  }

  editObj(props: any) {
    this.objObservationCrm = props
    this.showList = false
    this.action = 'edit'
  }
  async deleteObj() {
    try {
      await http.delete(`api/crm/crm_observation/${this.objObservationCrm.id}/`)
      this.data = this.data.filter(
        (a: ObservationCrm) => a.id !== this.objObservationCrm.id,
      )
      this.delete_modal_active = false
      this.getObservationCrm()
    } catch (error) {
      console.log(error)
      //toast.error("An error has occurred please try again.")
    }
  }
  deleteConfirm(props: any) {
    this.delete_modal_active = true
    this.objObservationCrm = props
  }
  closeModal() {
    this.objObservationCrm = new ObservationCrm()
    this.delete_modal_active = false
  }
  openForm() {
    this.showList = false
  }
  async cleanForm() {
    ;(this.$refs.header as any).showList = true
    this.objObservationCrm = new ObservationCrm()
    this.showList = true
    this.action = 'add'
    await this.getObservationCrm()
  }
  isData(data: any) {
    this.data = data
  }
}
